import React from "react"
import styled from "styled-components"
import { breakpoints, spacers } from "../styles/styles"

const HalfFlexDiv = styled.div`
        display: flex;
        flex-direction: column;
        margin-bottom: ${spacers.s}px;

        > *:first-of-type{
            margin-bottom: ${spacers.s}px;
        }

    @media screen AND (min-width: ${breakpoints.m}px) {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: ${spacers.m}px;
        margin-bottom: ${spacers.m}px;

        > *:first-of-type{
            margin-bottom: 0;
        }
    }

    @media screen AND (min-width: ${breakpoints.l}px) {
        grid-column-gap: ${spacers.l}px;
        margin-bottom: ${spacers.l}px;
    }

    &.noBottomSpacing {
        margin-bottom: 0;
    }
`

const HalfFlex = ({children, noBottomSpacing}) => <HalfFlexDiv className={noBottomSpacing ? "noBottomSpacing" : ""}>{children}</HalfFlexDiv>

export default HalfFlex