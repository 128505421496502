import React from "react"
import styled from "styled-components"
import { breakpoints } from "../styles/styles"

const MobileHiddenDiv = styled.div`
    display: none;

    @media screen AND (min-width: ${breakpoints.m}px) {
        display: inherit;
    }
`

const MobileHidden = ({children}) => <MobileHiddenDiv>{children}</MobileHiddenDiv> 

export default MobileHidden