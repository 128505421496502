import React from "react"
import HalfFlex from "../components/HalfFlex"
import MobileHidden from "../components/MobileHidden"
import Default from "../layouts/default"

const PageInfo = {
    desc: "Pension in zentraler Lage direkt am Markt mit modern eingerichteten Zimmern und Ferienwohnungen für 1 – 4 Personen",
    keywords: "ueber uns, pension, erzgebirge, zimmer, zschopau, hotel, ferienwohnung, zur altdeutschen, einzelzimmer, doppelzimmer",
    title: 'Über uns – Pension "Zur Altdeutschen"'
}

const UeberUns = () => <Default pageInfo={PageInfo} headerImg="/assets/img/AdobeStock_169956678.jpeg" isActive="about">
    <HalfFlex>
        <div>
            <h1>Über uns</h1>
            <p>
                Wir, Eva und Karl-Heinz Schönstein, haben uns ganz dem Wohl unserer Gäste verschrieben. 
                1990 erwarben wir das Geschäftshaus auf dem Neumarkt 1. Nach einer Komplettsanierung wurde erst das Restaurant und 1993 die Pension eröffnet.
            </p>
        </div>
        <div>
            <img src="/assets/img/IMG-20210110-WA0000.jpg" alt="Die Hotel – Pension zu Wendezeiten" />
        </div>
    </HalfFlex>
    <HalfFlex>
        <div >
            <MobileHidden><img src="/assets/img/pension-quer.jpg" alt="Hotel – Pension nach dem Umbau"/></MobileHidden>
        </div>
        <div>
            <p>
                 Seit 2012 konzentrieren wir uns ausschließlich auf den Pensionsbetrieb. Mit regelmäßigen Verschönerungsmaßnahmen wollen wir den hohen Ansprüchen unserer Gäste gerecht werden.
            </p>
        </div>
    </HalfFlex>
    <HalfFlex>
        <div>
            <p>
                Im Zweitberuf pflegen wir eine Imkerei. Ca. 20 Bienenvölker stehen jedes Jahr naturgesunder Umgebung in und um Zschopau.
                Eine Auswahl unserer Produkte bieten wir Gästen und Einheimischen im Empfangsbereich an.
            </p>
        </div>
        <div>
            <img src="/assets/img/produkte-600.png" alt="Bienenhonig-Produkte aus eigener Imkerei" />
        </div>
    </HalfFlex>
</Default>

export default UeberUns