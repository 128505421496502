import React from "react"
import styled from "styled-components"
import {Helmet} from "react-helmet"

import "../styles/normalize.css"
import "../styles/fonts.css"
import "../styles/base.css"

import Header from "../components/Header"
import Wrapper from "../components/Wrapper"
import Footer from "../components/Footer"

const DefaultDiv = styled.div`
margin-top: 55px;
`

const Default = ({children, headerImg, isActive, pageInfo}) => <DefaultDiv>
    <Helmet>
        <link rel="apple-touch-icon" sizes="180x180" href="/assets/favicon/apple-touch-icon.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/assets/favicon/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/assets/favicon/favicon-16x16.png" />
        <link rel="manifest" href="/assets/favicon/site.webmanifest" />
        <link rel="mask-icon" href="/assets/favicon/safari-pinned-tab.svg" color="#5bbad5" />
        <meta name="msapplication-TileColor" content="#da532c" />
        <meta name="theme-color" content="#ffffff" />
        <meta name="description" content={pageInfo.desc} />
        <meta name="keywords" content={pageInfo.keywords} />
        <title>{pageInfo.title}</title>
    </Helmet>
    <Header headerImg={headerImg} isActive={isActive}/>
    <Wrapper>{children}</Wrapper>
    <Footer />
    </DefaultDiv>

export default Default